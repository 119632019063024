
export default {
    emoji: '🇳🇵',
    created_at: 'सिर्जना गरिएको',
    updated_at: 'परिमार्जन गरिएको',
    crm: {
        title: 'CRM',
        dashboard: 'ड्यासबोर्ड',
        customers: 'ग्राहकहरू',
    },
    navigation: {
        home: 'गृहपृष्ठ',
        users: 'प्रयोगकर्ता',
        domains: 'डोमेनहरू',
        test: 'परीक्षण',
        notFound: 'पृष्ठ फेला परेन',
        forbidden: 'प्रवेश निषेधित',
        serverError: 'सर्भर त्रुटि',
        login: 'लगइन',
        settings: 'सेटिङ्स',
        register: 'रेजिस्टर',
        organizations: 'संस्थाहरू',
        accountGroup: 'खाता समूहहरू',
        companies: 'कम्पनीहरू',
        individuals: 'व्यक्तिहरू',
        client: 'ग्राहक',
        booking_management: 'बुकिङ प्रबन्धन',
        booking: 'बुकिङ',
        booking_forwards: 'बुकिङ फरवार्ड',
        booking_delivery_pickup: 'बुकिङ डेलिभरी/पिकअप',
        management: 'प्रबन्धन',
        awb_inventory: 'AWB इन्भेन्टरी',
        hscode: 'HS कोड',
    },
    forms: {
        create: 'नयाँ विवरण',
        view: 'विवरण हेर्नुहुदैछ',
        update: 'सम्पादन गर्दैहुनुहुन्छ',
        FormAccountGroups: 'खाता समूह',
        FormBookingForwards: 'बुकिङ फरवार्ड',
        FormBookings: 'बुकिङ',
        FormCompanies: 'कम्पनी',
        FormCustomerCompanies: 'कम्पनी',
        FormDeliveryPickupOrders: 'डेलिभरी/पिकअप आदेश',
        FormDomainOrganizations: 'कम्पनी',
        FormIndividuals: 'व्यक्तिहरू',
        FormUsers: 'प्रयोगकर्ता',
        FormUserSettings: 'प्रयोगकर्ता',
        FormDomains: 'डोमेनहरू',
    },
    form_action: {
        save: 'सेभ गर्नुहोस्',
        submit: 'पेश गर्नुहोस्',
        clear: 'मेटाउनुहोस्',
        cancel: 'रद्द गर्नुहोस्',
        close: 'बन्द गर्नुहोस्',
        delete: 'मेटाउनुहोस्',
        edit: 'सम्पादन गर्नुहोस्',
        update: 'अपडेट गर्नुहोस्',
        add: 'थप्नुहोस्',
        FormRecordCreatedSuccess: 'विवरण सफलतापूर्वक सिर्जना भयो।',
        FormRecordCreatedFailure: 'विवरण सिर्जना गर्न असफल भयो।',
        FormRecordUpdatedSuccess: 'विवरण सफलतापूर्वक अपडेट भयो।',
        FormRecordUpdatedFailure: 'विवरण अपडेट गर्न असफल भयो।',
        FormRecordFetchFailure: 'विवरण हेर्न असफल भयो।',
    },
    validation: {
        phone: 'फोन नम्बर अधिकतम १० अंक, कम्तीमा ६ अंक हुनुपर्छ।',
        extension: "एक्सटेन्सन ठीक ६ अंक भन्दा बढी हुनुपर्छ।",
        intlCode: "अमान्य अन्तर्राष्ट्रिय कोड प्रारूप।",
        required: "यो क्षेत्र आवश्यक छ।",
        success: 'अनुरोध सफलतापूर्वक पूरा गरियो।',
        error: 'तपाईंको फारममा केहि त्रुटिहरू छन्। कृपया यिनलाई सुधार गर्नुहोस् र पुन: प्रयास गर्नुहोस्।',
    },
    v: {
        required: "फिल्ड आवश्यक छ।",
        default: "फिल्डमा मान्य डाटा हुनु पर्दछ।",
        alpha: "फिल्डमा वर्णमाला अक्षरहरू मात्र समावेश गर्न सकिन्छ।",
        alpha_dash: "फिल्डमा वर्णमाला अक्षरहरू साथै रेफ तथा अंडरस्कोर समावेश गर्न सकिन्छ।",
        alpha_num: "फिल्डमा संख्या वा अक्षरहरू मात्र समावेश गर्न सकिन्छ।",
        alpha_spaces: "फिल्डमा वर्णमाला अक्षरहरू र यिन बीच खाली ठाउँ मात्र समावेश गर्न सकिन्छ।",
        between: "यसको डाटा {a} र {b} को बीच हुनु पर्दछ।",
        confirmed: "यसको मान पुष्टिकरण फिल्डसंग मेल खाँदैन।",
        digits: "फिल्डमा अंकहरू समावेश हुनुका साथै ठ्याकै {a}ओटा अंक समावेश हुनु पर्दछ।",
        dimensions: "फिल्डमा {a} पिक्सेल चौड़ा र {b} पिक्सेल अग्लो हुनु पर्दछ।",
        email: "फिल्डमा मान्य ईमेल हुनु पर्दछ।",
        not_one_of: "फिल्डमा मान्य मान हुनु पर्दछ।",
        ext: "फिल्डमा मान्य फाइल हुनु पर्दछ।",
        image: "फिल्डमा मान्य फोटो हुनु पर्दछ।",
        one_of: "फिल्डमा मान्य परिमाण हुनु पर्दछ।",
        integer: "फिल्डको मान पूर्णांक संख्या हुनु पर्दछ।",
        length: "फिल्डमा लम्बाई {a} हुनु पर्दछ।",
        max: "फिल्डमा अक्षरहरू {a}सम्म लामो हुँदा मान्य हुनेछ।",
        max_value: "फिल्डमा अंकहरु {a} वा सोभन्दा कम हुनु पर्दछ।",
        mimes: "फिल्ड मान्य फाइलको प्रकार हुनु पर्दछ।",
        min: "फिल्ड कम्तिमा {a} अक्षर वा अंकहरू हुनु पर्दछ।",
        min_value: "फिल्डमा अंकहरु {a} वा बढी हुनु पर्दछ।",
        numeric: "फिल्डमा संख्यात्मक अंकहरू मात्र समावेश गर्दा मान्य हुनेछ",
        regex: "फिल्डमा मानको ढाँचा स्वीकार-योग्य छैन।",
        required_if: "फिल्ड आवश्यक छ।",
        size: "परिणाम {a}KB भन्दा कम हुनु पर्दछ।",
        url: "फिल्डमा मान्य URL हुनु पर्दछ।",
        exists: "दिइएको मान पहिले नै डाटाबेसमा अवस्थित हुनु पर्दछ।",
    },
    grid: {
        select_record: 'कृपया विवरण हेर्नको लागि एक रेकर्ड चयन गर्नुहोस्।',
        create: 'नयाँ रेकर्ड',
        update: 'रेकर्ड सम्पादन',
        delete: 'रेकर्ड मेटाउनुहोस्',
        read: 'विवरण हेर्नुहोस्',
        refresh: 'रिफ्रेस',
        refresh_table: 'ग्रिड रिफ्रेस गर्नुहोस्',
        cancel: 'रद्द गर्नुहोस्',
        error_delete: 'रेकर्ड हटाउन सकिएन।',
        success_delete: 'रेकर्ड सफलतापूर्वक हटाइयो।',
        success_restore: 'रेकर्ड सफलतापूर्वक पुनर्स्थापन गरियो।',
        confirm_delete: 'मेटाउन चाहनुहुन्छ?',
        confirm_delete_text: 'के तपाईं निश्चित रूपमा मेटाउन चाहनुहुन्छ?',
        error_restore: 'रेकर्ड रिस्टोर गर्न सकिएन।',
        confirm_restore: 'पुनर्स्थापना गर्न चाहनुहुन्छ?',
        confirm_restore_text: 'यसले अनपेक्षित साइड इफेक्ट निम्त्याउन सक्छ। के तपाइँ जारी राख्न निश्चित हुनुहुन्छ?',
        restore: 'पुनर्स्थापन गर्नुहोस्',
    },
    settings: 'सेटिङ्स',
    active: 'सक्रिय',
    inactive: 'निष्क्रिय',
    createdAt: 'सिर्जना गरिएको',
    updatedAt: 'अपडेट गरिएको',
    deletedAt: 'मेटिएको',
};
