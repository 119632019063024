import en from './en.js';
import jp from './jp.js';
import np from './np.js';

const defaults = [
    {
        "label": "🇺🇸 English",
        "code": "en"
    },
    {
        "label": "🇯🇵 日本語",
        "code": "jp"
    },
    {
        "label": "🇳🇵 नेपाली",
        "code": "np"
    }
];

export default {
  locales: {
    en: en,
    jp: jp,
    np: np
  },
  all: defaults,
};
