import gH from '@/helpers';
import { useStore } from 'vuex';

let redirection = {
    configureRedirection: (router) => {
        router.beforeEach((to, from, next) => {
            const store = useStore();
            store.commit('core/updateLoading', { value: true }, { root: true });
            const authRequired = !['/login', '/404', '/500'].includes(to.path);
            const user = JSON.parse(sessionStorage.getItem('user'));
            const toPath = sessionStorage.getItem('X-REDIRECTION');

            if (authRequired && !user && !gH.isValidToken()) {
                if (to.path !== '/settings') {
                    sessionStorage.setItem('X-REDIRECTION', to.path);
                }
                return next('/login');
            }

            if (from.path === '/login' && !sessionStorage.getItem('X-ORG')) {
                return next('/settings');
            }

            if (from.path === '/settings') {
                sessionStorage.removeItem('X-REDIRECTION');
                return next(toPath);
            }

            next();
        });
    },
};

export default redirection;
