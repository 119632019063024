import { registerGlobalComponents } from './globalComponents.js';
import { createApp, reactive } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import swal from 'vue-sweetalert2';

// Create the application instance.
const app = createApp(App);

// Addendum to Vue.
import CoreuiVue from '@coreui/vue';
import CIcon from '@coreui/icons-vue';
import { iconsSet as icons } from '@/assets/icons';

// Register Syncfusion License.
import { registerLicense } from '@syncfusion/ej2-base';
registerLicense(import.meta.env.VITE_SYNCFUSION_LICENSE_KEY);

// Setup middleware.
import auth from './auth';
auth.configureRedirection(router, store);

// Setup cron for token refresh.
import authToken from './auth/tokenRefresh';
app.config.globalProperties.$refreshToken = authToken.refreshToken;
setInterval(authToken.refreshToken, authToken.interval);

// Boot the application.
app.use(store);
app.use(router);
app.use(CoreuiVue);
app.use(swal);
app.provide('icons', icons);
app.component('CIcon', CIcon);

// Setup i18n.
import { i18n, changeLocale } from './i18n';
import i18nFiles from '@/i18n/index';
const reactiveData = reactive({});

app.config.globalProperties.$availableLocales = i18nFiles.all;
app.config.globalProperties.$reactiveData = reactiveData;
app.config.globalProperties.$changeLocale = async (locale) => {
    await changeLocale(locale, router);
    reactiveData.locale = locale;
};
app.use(i18n);

// Setup vue-multiple-select.
import VueSelect from "vue-select";
app.component("v-select", VueSelect);

// Import the global helper from @/store/helperMethods
import helperMethods from '@/helpers';
import { can, is } from '@/auth/permission.js';
app.use((app) => {
    app.config.globalProperties.$gh = helperMethods;
    app.config.globalProperties.$gh.showLoading = helperMethods.showLoading.bind(null, store);
    app.config.globalProperties.$gh.hideLoading = helperMethods.hideLoading.bind(null, store);
    app.config.globalProperties.$gh.storeCommit = helperMethods.storeCommit.bind(null, store);
    app.config.globalProperties.$can = can;
    window.can = can;
    window.is = is;
    app.config.globalProperties.$is = is;
});

// Syncfusion Dialogue.
import { DialogPlugin } from '@syncfusion/ej2-vue-popups';
app.use(DialogPlugin);
registerGlobalComponents(app);
