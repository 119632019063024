import axios from "axios";

class ajaxHelper {
    constructor() {
        axios.defaults.baseURL = import.meta.env.VITE_BASE_URI;
        axios.defaults.headers.post['Content-Type'] = 'application/json';
    }
    setAuthHeader(token = null) {
        if ( token !== null ) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
            axios.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
        }
    }
    async resetAuthHeader() {
        axios.defaults.headers.common['Authorization'] = null;
    }
    async get(url, data = {}) {
        return axios.get(url, data);
    }
    async post(url, data, options = {}) {
        return axios.post(url, data);
    }
    async put(url, data = {}) {
        return axios.put(url, data);
    }
    async delete(url, data = {}) {
        return axios.delete(url, data);
    }
    async patch(url, data = {}) {
        return axios.patch(url, data);
    }
    async options(url, data = {}) {
        return axios.options(url, data);
    }
    async head(url, data = {}) {
        return axios.head(url, data);
    }
}

export default new ajaxHelper();
