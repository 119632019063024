import { auth, ajax } from '@/store/helpers';
import router from '@/router';

const user = auth.getToken();
const state = user
    ? { status: { loggedIn: true }, user }
    : { status: {}, user: null };

const actions = {
    login({ dispatch, commit }, data) {
        commit('core/updateLoading', { value: true }, { root: true });
        auth.login(data).then(
            (xhr) => {
                if (200 === xhr?.data?.status && xhr?.data?.user && xhr?.data?.token) {
                    commit("instantiate", xhr?.data?.user);
                    auth.setUser(xhr?.data);
                    ajax.get('settings/locale').then(
                        (xhr) => {
                            if ('200' === xhr?.data?.code && xhr?.data?.data?.locale) {
                                sessionStorage.setItem('locale', xhr.data.data.locale);
                            }
                        }
                    ).catch().finally(() => {
                        // Check to see if X-ORG is set. If not, push to /settings
                        if ( 'null' !== sessionStorage.getItem('X-ORG') ) {
                            let redirection = sessionStorage.getItem('X-REDIRECTION');
                            if ( redirection ) {
                                sessionStorage.removeItem('X-REDIRECTION');
                                router.push(redirection);
                            } else {
                                router.push("/settings");
                            }
                        } else {
                            router.push("/settings");
                        }
                    });

                } else {
                    router.push("/login");
                }
                commit('core/updateLoading', { value: false }, { root: true });
            }
        ).catch((error) => {
            commit("destroy", { status: 'error', message: error, confirm: 'OK' });
            dispatch("alert/error", { message: error?.response?.data?.message || 'Login failed' }, { root: true });
            commit('core/updateLoading', { value: false }, { root: true });
        });
    },
    logout({ commit }) {
        commit('core/updateLoading', { value: true }, { root: true });
        auth.logout();
        commit("destroy", { status: 'loggedOut', message: null });
        commit('core/updateLoading', { value: false }, { root: true });
        router.push("/login");
    },
};

const mutations = {
    instantiate(state, user) {
        state.status = 'loggedIn';
        state.user = user;
        state.message = null;
    },
    destroy(state, data = null) {
        state.status = data.status || 'loggedOut';
        state.message = data.message || null;
        state.user = null;
    }
};

const getters = {
    returnState: (state) => {
        return state;
    },
    userAccount: () => {
        return auth.getUser();
    },
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
