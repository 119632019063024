<template>
    <CHeader position="sticky" class="mb-4">
        <CContainer fluid>
            <div class="d-flex justify-content-start">
                <CHeaderToggler class="ps-1 d-flex" @click="$store.commit('core/toggleSidebar')">
                    <CIcon icon="cil-menu" size="xxl" />
                </CHeaderToggler>
                <CHeaderNav class="d-none d-md-flex me-auto">
                    <CNavItem>
                        <CNavLink href="#/dashboard"> {{ $t('dashboard') }} </CNavLink>
                    </CNavItem>
                </CHeaderNav>
            </div>
            <div class="d-flex justify-content-end">
                <CHeaderNav class="d-flex">
                    <CNavItem>
                        <div v-if="!this.loading">
                            <CButton @click="this.goToPreviousView">
                                <CIcon class="mx-2" icon="cil-arrow-left" size="lg" />
                            </CButton>
                        </div>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink href="#">
                            <CIcon class="mx-2" icon="cil-bell" size="lg" />
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink href="#">
                            <CIcon class="mx-2" icon="cil-list" size="lg" />
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <CNavLink href="#">
                            <CIcon class="mx-2" icon="cil-envelope-open" size="lg" />
                        </CNavLink>
                    </CNavItem>
                    <CNavItem>
                        <div v-if="!this.loading">
                            <CButton @click="this.fetchData">
                                <CIcon class="mx-2" icon="cil-reload" size="lg" />
                            </CButton>
                        </div>
                    </CNavItem>
                    <AppHeaderDropdownAccnt />
                </CHeaderNav>
            </div>
        </CContainer>
        <CHeaderDivider />
        <CContainer fluid>
            <AppBreadcrumb />
        </CContainer>
    </CHeader>
</template>

<script>
import AppBreadcrumb from '@/components/AppBreadcrumb.vue'
import AppHeaderDropdownAccnt from '@/components/AppHeaderDropdownAccnt.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
import { CButton } from '@coreui/vue'

export default {
    name: 'AppHeader',
    components: {
        AppBreadcrumb,
        AppHeaderDropdownAccnt,
        CButton
    },
    setup() {
        const store = useStore()
        return {
            loading: computed(() => store.state.core.loading),
            globalAppState: computed(() => store.state.core.globalAppState),
        }
    },
    methods: {
        fetchData: function () {
            this.$refreshToken(true);
        },
        goToPreviousView: function () {
            this.$router.go(-1);
        }
    },
    mounted() {
        window.addEventListener('keydown', (e) => {
            if (
                ((e.key === 'F5' ||
                    (e.key === 'r' && e.ctrlKey)) || // F5 or Ctrl+R
                    (e.key === 'F5' && e.shiftKey)) && // F5 or Ctrl+R
                !this.loading
            ) {
                e.preventDefault();
                this.fetchData();
            }
        });

    },

    i18n: {
        messages: {
            en: {
                dashboard: 'Dashboard',
            },
            np: {
                dashboard: 'ड्यासबोर्ड',
            },
            jp: {
                dashboard: 'ダッシュボード',

            },
        }
    },
}
</script>
