export const core = {
    namespaced: true,
    state: {
        sidebarVisible: '',
        sidebarUnfoldable: true,
        loading: false,
        globalAppState: 0,
        menu: [
            {
                "component": "CNavItem",
                "name": "Dashboard",
                "to": "/dashboard",
                "icon": "cil-speedometer",
            }
        ],
    },
    getters: {
        getMenu: state => {
            if (sessionStorage.getItem('X-MENU')) {
                state.menu = JSON.parse(sessionStorage.getItem('X-MENU'));
            }

            return state.menu;
        }
    },
    mutations: {
        toggleSidebar(state) {
            state.sidebarVisible = !state.sidebarVisible
        },
        toggleUnfoldable(state) {
            state.sidebarUnfoldable = !state.sidebarUnfoldable
        },
        updateSidebarVisible(state, payload) {
            state.sidebarVisible = payload.value
        },
        updateLoading(state, payload) {
            state.loading = payload.value
        },
        updateMenu(state, payload) {
            sessionStorage.setItem('X-MENU', JSON.stringify(payload.data));
            state.menu = payload.data;
        },
        updateApplicationState(state) {
            state.globalAppState++;
        }
    },
};
