<template>
    <CDropdown variant="nav-item">
        <CDropdownToggle href="javascript: void(0)" placement="bottom-end" class="py-0" :caret="false">
            <CAvatar :src="this.avatar" size="md" status="success" />
        </CDropdownToggle>
        <CDropdownMenu class="pt-0">
            <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
                Account
            </CDropdownHeader>
            <CDropdownItem>
                <CIcon icon="cil-bell" /> Updates
                <CBadge color="info" class="ms-auto">{{ itemsCount }}</CBadge>
            </CDropdownItem>
            <CDropdownItem>
                <CIcon icon="cil-envelope-open" /> Messages
                <CBadge color="success" class="ms-auto">{{ itemsCount }}</CBadge>
            </CDropdownItem>
            <CDropdownItem>
                <CIcon icon="cil-dollar" /> Tasks
                <CBadge color="danger" class="ms-auto">{{ itemsCount }}</CBadge>
            </CDropdownItem>
            <CDropdownItem>
                <CIcon icon="cil-comment-square" /> Comments
                <CBadge color="warning" class="ms-auto">{{ itemsCount }}</CBadge>
            </CDropdownItem>
            <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
                Settings
            </CDropdownHeader>
            <CDropdownItem>
                <CIcon icon="cil-user" /> Profile
            </CDropdownItem>
            <CDropdownItem @click="this.$router.push('/settings')">
                <CIcon icon="cil-settings" /> Settings
            </CDropdownItem>
            <CDropdownDivider />
            <!-- <CDropdownItem> This should be implemented on a browser level later on.
                <CIcon icon="cil-shield-alt" /> Lock Account
            </CDropdownItem> -->
            <CDropdownItem @click="this.logout()">
                <CIcon icon="cil-lock-locked" /> Logout
            </CDropdownItem>
        </CDropdownMenu>
    </CDropdown>
</template>

<style>
a.dropdown-item {
    cursor: pointer;
}
</style>

<script>
// import avatar from '@/assets/images/avatar.png';
import { useStore } from 'vuex';
export default {
    name: 'AppHeaderDropdownAccnt',
    setup() {
        return {
            store: useStore(),
        }
    },
    mounted: function () {
        this.avatar = this.$gh.fetchUserData('user')?.avatar;
    },
    data() {
        return {
            avatar: 'https://gravatar.com/avatar/?d=mp',
            itemsCount: 42,
        }
    },
    methods: {
        logout() {
            this.store.dispatch('account/logout');
        },
    },
}
</script>
