import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout.vue'

const routes = [
  {
    path: '/',
    name: 'crm.dashboard',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'navigation.home',
        component: () =>
          import('@/views/Dashboard.vue'),
      },
      {
        path: '/organizations',
        name: 'navigation.organizations',
        component: () => import( '@/grids/GridDomainOrganizations.vue'),
      },
      {
        path: '/harmonized-system-codes',
        name: 'navigation.hscode',
        component: () => import( '@/grids/GridHSCode.vue'),
      },
      {
        path: '/users',
        name: 'navigation.users',
        component: () => import('@/grids/GridUsers.vue'),
      },
      {
        path: '/domains',
        name: 'navigation.domains',
        component: () => import('@/grids/GridDomains.vue'),
      },
      {
        path: '/test',
        name: 'navigation.test',
        component: () =>
        import( '@/components/Test.vue'),
      },
      {
        path: '/client',
        name: 'navigation.client',
        redirect: '/client/account-group',
        children: [
          {
            path: '/client/account-group',
            name: 'navigation.accountGroup',
            component: () => import( '@/grids/GridAccountGroups.vue'),
          },
          {
            path: '/client/companies',
            name: 'navigation.companies',
            component: () => import('@/grids/GridCompanies.vue'),
          },
          {
            path: '/client/individuals',
            name: 'navigation.individuals',
            component: () => import('@/grids/GridIndividuals.vue'),
          },
        ],
      },
      {
        path: '/booking',
        name: 'navigation.booking_management',
        redirect: '/booking/dashboard',
        children: [
          {
            path: '/booking/dashboard',
            name: 'navigation.booking',
            component: () => import( '@/grids/GridBookings.vue'),
          },
          {
            path: '/booking/forwards',
            name: 'navigation.booking_forwards',
            component: () => import('@/grids/GridBookingForwards.vue'),
          },
          {
            path: '/booking/delivery-pickup',
            name: 'navigation.booking_delivery_pickup',
            component: () => import('@/grids/GridDeliveryPickupOrders.vue'),
          },
        ],
      },
      {
        path: '/manage',
        name: 'navigation.management',
        redirect: '/manage/dashboard',
        children: [
          {
            path: '/manage/awb-inventory',
            name: 'navigation.awb_inventory',
            component: () => import( '@/grids/GridAWBInventory.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/:notFound',
    name: 'navigation.notFound',
    component: () => import('@/views/Page404.vue'),
  },
  {
    path: '/:catchAll(.*)',
    redirect: { path: '/404' }
  },
  {
    path: '/403',
    name: 'navigation.forbidden',
    component: () => import('@/views/Page403.vue'),
  },
  {
    path: '/500',
    name: 'navigation.serverError',
    component: () => import('@/views/Page500.vue'),
  },
  {
    path: '/login',
    name: 'navigation.login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/settings',
    name: 'navigation.settings',
    component: () => import('@/views/Settings.vue'),
  },
  {
    path: '/register',
    name: 'navigation.register',
    component: () => import('@/views/Register.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
