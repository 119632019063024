import ajax from '@/store/helpers/ajax';
import { jwtDecode } from "jwt-decode";

const globalHelpers = {
    date: () => {
        let date = new Date();
        return date.toISOString().split('T')[0];
    },
    log: (param) => {
        console.log(`[${new Date().toLocaleString()}] ${param}`);
    },
    boot: ajax.setAuthHeader,
    get: ajax.get,
    post: ajax.post,
    put: ajax.put,
    delete: ajax.delete,
    patch: ajax.patch,
    options: ajax.options,
    head: ajax.head,
    refreshToken: (org_id) => {
        return ajax.post('auth/refresh', { 'X-ORG': org_id });
    },
    can(permission) {
        const userPermissions = JSON.parse(sessionStorage.getItem('X-PERMISSIONS'));
        const userRoles = JSON.parse(sessionStorage.getItem('X-ROLES'));

        if (
            userRoles &&
            (
                userRoles.includes('Developer') ||
                userRoles.includes('Global Administrator') ||
                userRoles.includes('Domain Administrator')
            )) {
            return true;
        }

        if (userPermissions && userPermissions.includes(permission)) {
            return true;
        }
        return false;
    },
    fetchUserData(dataType = null) {
        try {
            if (sessionStorage.getItem('token') && sessionStorage.getItem('X-ORG')) {
                switch (dataType) {
                    case 'user':
                        return JSON.parse(sessionStorage.getItem('X-USER'));
                    case 'org':
                        return JSON.parse(sessionStorage.getItem('X-ORG'));
                    case 'orgs':
                        return JSON.parse(sessionStorage.getItem('X-ORGS'));
                    case 'timezone':
                        return sessionStorage.getItem('X-TZ');
                    case 'permissions':
                        return JSON.parse(sessionStorage.getItem('X-PERMISSIONS'));
                    case 'roles':
                        return JSON.parse(sessionStorage.getItem('X-ROLES'));
                    case 'token':
                        return sessionStorage.getItem('token');
                    default:
                        return new Object({
                            user: JSON.parse(sessionStorage.getItem('X-USER')),
                            orgs: JSON.parse(sessionStorage.getItem('X-ORGS')),
                            currentOrg: JSON.parse(sessionStorage.getItem('X-ORG')),
                            timezone: sessionStorage.getItem('X-TZ'),
                            token: sessionStorage.getItem('token'),
                            permissions: JSON.parse(sessionStorage.getItem('X-PERMISSIONS')),
                            roles: JSON.parse(sessionStorage.getItem('X-PERMISSIONS'))
                        });
                }
            } else {
                throw '[bail]';
            }
        } catch (e) {
            return null;
        }
    },
    saveUserData(dataType, data) {
        try {
            if (dataType && data) {
                switch (dataType) {
                    case 'user':
                        sessionStorage.setItem('X-USER', JSON.stringify(data));
                        break;
                    case 'org':
                        sessionStorage.setItem('X-ORG', JSON.stringify(data));
                        break;
                    case 'orgs':
                        sessionStorage.setItem('X-ORGS', JSON.stringify(data));
                        break;
                    case 'timezone':
                        sessionStorage.setItem('X-TZ', data);
                        break;
                    case 'permissions':
                        sessionStorage.setItem('X-PERMISSIONS', JSON.stringify(data));
                        break;
                    case 'roles':
                        sessionStorage.setItem('X-ROLES', JSON.stringify(data));
                        break;
                    case 'token':
                        sessionStorage.setItem('token', data);
                        break;
                    default:
                        return false;
                }
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    },
    getMenu: () => {
        return ajax.get('settings/menu');
    },
    getIntlCode: () => {
        let locale = sessionStorage.getItem('locale');
        if (!locale) {
            return 'en-US';
        }

        switch (locale) {
            case 'en':
                return 'en-US';
            case 'np':
                return 'ne-NP';
            case 'jp':
                return 'ja-JP';
            default:
                return 'en-US';
        }
    },
    decode: (token) => {
        try {
            const decodedToken = jwtDecode(token);
            return decodedToken;
        } catch (error) {
            return null;
        }
    },
    getToken: () => {
        return sessionStorage.getItem('token');
    },
    getFromToken: (param) => {
        try {
            const decodedToken = jwtDecode(sessionStorage.getItem('token'));
            if (param) {
                return decodedToken[param];
            } return decodedToken;
        } catch (error) {
            return null;
        }
    },
    time: (unix_epoch) => {
        return new Date(1000 * unix_epoch);
    },
    isValidToken: () => {
        try {
            const decodedToken = jwtDecode(sessionStorage.getItem('token'));
            let expDateTime = new Date(1000 * decodedToken.exp);
            return expDateTime > new Date();
        } catch (error) {
            return false;
        }
    },
    storeCommit: (store, mutation, payload) => {
        store.commit(mutation, payload, { root: true });
    },
    showLoading: function (store) {
        store.commit('core/updateLoading', { value: true }, { root: true });
    },
    hideLoading: function (store) {
        store.commit('core/updateLoading', { value: false }, { root: true });
    },
    debounce: function (func, wait, immediate) {
        let timeout;
        return function () {
            let context = this, args = arguments;
            clearTimeout(timeout);
            if (immediate && !timeout) func.apply(context, args);
            timeout = setTimeout(function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            }, wait);
        };
    },
    computedPhoneNumber: function (data) {
        if (data?.phone) {
            const numericPhone = data?.phone.replace(/\D/g, '');
            const formattedPhone = numericPhone.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
            const formattedExt = data?.extension ? ` (${data?.extension})` : '';
            return `${data?.intlCode?.code + '-' || ''}${formattedPhone}${formattedExt}`;
        }

        return null;
    },
    composeURL: function (odata, id = null, version = 'v1', snakeOnly = false) {
        let url = odata.split('/').pop();
        if (snakeOnly) {
            return url;
        }
        return `${version}/${url}${id ? `/${id}` : ''}`;
    },
    getOdataEndpoint: function (url, version = 'v1') {
        let endpoint = url.split('/').pop();
        return `${version}/${endpoint}`;
    },
};

export default globalHelpers;
