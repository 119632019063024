export default {
    emoji: '🇯🇵',
    created_at: '作成日',
    updated_at: '更新日',
    crm: {
        title: 'CRM',
        dashboard: 'ダッシュボード',
        customers: '顧客',
    },
    navigation: {
        home: 'ホーム',
        users: 'ユーザー',
        domains: 'ドメイン',
        test: 'テスト',
        notFound: 'ページが見つかりません',
        forbidden: '禁止されています',
        serverError: 'サーバーエラー',
        login: 'ログイン',
        settings: '設定',
        register: '登録',
        organizations: '組織',
        accountGroup: 'アカウントグループ',
        companies: '会社',
        individuals: '個人',
        client: 'クライアント',
        booking_management: '予約管理',
        booking: '予約',
        booking_forwards: '予約転送',
        booking_delivery_slash_pickup: '予約配達/ピックアップ',
        management: '管理',
        awb_inventory: 'AWB在庫',
        hscode: 'HSコード',
    },
    forms: {
        create: '新しいレコード',
        view: 'レコードの表示',
        update: 'レコードの編集',
        FormAccountGroups: 'アカウントグループ',
        FormBookingForwards: '予約転送',
        FormBookings: '予約',
        FormCompanies: '会社',
        FormCustomerCompanies: '会社',
        FormDeliveryPickupOrders: '配達/ピックアップ注文',
        FormDomainOrganizations: '会社',
        FormIndividuals: '個人',
        FormUsers: 'ユーザー',
        FormUserSettings: 'ユーザー',
        FormDomains: 'ドメイン',
    },
    form_action: {
        save: '保存',
        submit: '提出する',
        clear: 'クリア',
        cancel: 'キャンセル',
        close: '閉じる',
        delete: '削除',
        edit: '編集',
        update: '更新',
        add: '追加',
        FormRecordCreatedSuccess: 'レコードが正常に作成されました。',
        FormRecordCreatedFailure: 'レコードの作成に失敗しました。',
        FormRecordUpdatedSuccess: 'レコードが正常に更新されました。',
        FormRecordUpdatedFailure: 'レコードの更新に失敗しました。',
        FormRecordFetchFailure: 'レコードの取得に失敗しました。',
    },
    v: {
        required:"入力必須",
        default:"フィールドが無効です",
        alpha:"半角英字を入力してください",
        alpha_num:"半角英数字を入力してください",
        alpha_dash:"半角英数字か、 ” ' \"、\" _ \" 、を入力してください",
        alpha_spaces:"半角英字かスペースを入力してください",
        between:"半角数字を入力してください",
        confirmed:"フィールドが一致しません",
        digits: "{a} 桁の半角数字を入力してください",
        dimensions:"画像サイズは {a} ピクセル×{b} ピクセルでなければなりません",
        email:"有効な電子メールアドレスを入力してください",
        not_one_of:"フィールドの値が無効です",
        ext:"フィールドのファイルが無効です",
        image:"フィールドは画像でなければなりません",
        integer:"整数を入力してください",
        length:"フィールドは {a} 文字以上でなければなりません",
        max_value:"フィールドは {a} 文字以下でなければなりません",
        max:"フィールドは {a} 文字以下でなければなりません",
        mimes:"有効なファイルを選択してください",
        min_value:"フィールドは {a} 文字以上でなければなりません",
        min:"フィールドは {a} 文字以上でなければなりません",
        numeric:"半角英数字を入力してください",
        one_of:"フィールドの値が無効です",
        regex:"フィールドの形式が無効です",
        required_if:"入力必須",
        size:"ファイルのサイズは {a} KB以下でなければなりません",
        url:"フィールドのURL が無効です",
        exists: "{attribute} が存在しません",
    },
    validation: {
        phone: '電話番号は最大10桁、最小6桁である必要があります。',
        extension: "エクステンションは最大で6桁でなければなりません。",
        intlCode: "無効な国際コードの形式。",
        error: '送信にエラーがあります。修正してもう一度お試しください。',
        success: 'リクエストが正常に完了しました。',
    },
    grid: {
        select_record: '詳細を表示するにはレコードを選択してください。',
        create: '新しいレコード',
        update: '編集レコード',
        delete: '削除レコード',
        read: '詳細を表示',
        refresh: '更新する',
        refresh_table: 'グリッドを更新する',
        cancel: 'キャンセル',
        error_delete: 'レコードを削除できませんでした。',
        success_restore: 'データは正常に復元されました。',
        success_delete: 'レコードが正常に削除されました。',
        confirm_delete: '削除しますか？',
        confirm_delete_text: 'レコードは永久に削除される可能性があります。続行しますか?',
        error_restore: 'データを復元できませんでした。',
        confirm_restore: '復元を続行しますか?',
        confirm_restore_text: '続行すると他のデータに問題が発生する可能性があります。続行してもよろしいですか?',
        restore: '復元',
    },
    settings: '設定',
    active: 'アクティブ',
    inactive: '非アクティブ',
    createdAt: '作成日',
    updatedAt: '更新日',
    deletedAt: '削除日',
};
