import { createStore } from 'vuex';
import { core } from '@/store/modules/coreui';
import { alert } from '@/store/modules/alert';
import { account } from '@/store/modules/account';

export default createStore({
  modules: {
    alert,
    account,
    core
  },
})
