import swal from 'sweetalert2';

const state = {
    type: '',
    data: null
};

function callSwalToast(text = 'The request was successfully completed.', icon ='success') {
    return new swal({
        text: text,
        icon: icon,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });
}

const actions = {
    success({ commit }, data) {
        callSwalToast(data.message, 'success');
        commit('success', data);
    },
    warn({ commit }, data) {
        callSwalToast(data.message, 'warning');
        commit('warn', data);
    },
    error({ commit }, data) {
        callSwalToast(data.message, 'error');
        commit('error', data);
    },
    clear({ commit }) {
        commit('wipe');
    },
};

const mutations = {
    success(state, data) {
        state.type = 'alert-success';
        state.data = data;
    },
    error(state, data) {
        state.type = 'alert-danger';
        state.data = data;
    },
    warn(state, data) {
        state.type = 'alert-warning';
        state.data = data;
    },
    wipe(state) {
        state.type = '';
        state.data = null;
    }
};

export const alert = {
    namespaced: true,
    state,
    actions,
    mutations
};
