import {
    cilSpeedometer,
    cilContact,
    cilAirplaneMode,
    cilBuilding,
    cilSettings,
    cilCheck,
    cilMenu,
    cilBell,
    cilList,
    cilEnvelopeOpen,
    cilEnvelopeClosed,
    cilCommentSquare,
    cilUser,
    cilDollar,
    cilFile,
    cilShieldAlt,
    cilLockLocked,
    cilLockUnlocked,
    cilCloudDownload,
    cilPeople,
    cilLan,
    cilBook,
    cilReload,
    cilArrowLeft,
    cilPencil
} from '@coreui/icons'

export const iconsSet = Object.assign(
    {},
    {
        cilSpeedometer,
        cilContact,
        cilAirplaneMode,
        cilBuilding,
        cilSettings,
        cilCheck,
        cilMenu,
        cilBell,
        cilList,
        cilEnvelopeOpen,
        cilEnvelopeClosed,
        cilCommentSquare,
        cilUser,
        cilDollar,
        cilFile,
        cilShieldAlt,
        cilLockLocked,
        cilLockUnlocked,
        cilCloudDownload,
        cilPeople,
        cilLan,
        cilBook,
        cilReload,
        cilArrowLeft,
        cilPencil
    },
)
