import gH from '@/helpers';
import store from '@/store';

const durationInMinutes = 30;
const refreshInterval = durationInMinutes * 60 * 1000;
const delayWhenLoading = 6000;

const languageMessages = {
    en: 'Your session has expired. Please login again.',
    jp: 'セッションが期限切れです。再度ログインしてください。',
    np: 'तपाईंको सत्र समाप्त भयो। कृपया पुन:लगइन गर्नुहोस्।',
};

function getErrorMessageForLanguage(locale) {
    const language = locale || 'en';
    return languageMessages[language];
}

function handleTokenRefreshError() {
    const errorMessage = getErrorMessageForLanguage(sessionStorage.locale);
    store.dispatch('alert/error', { message: errorMessage });
    sessionStorage.setItem('X-REDIRECTION', window.location.hash.substr(1));
    store.commit('core/updateLoading', { value: false }, { root: true });
    store.dispatch('account/logout');
}

function refreshToken(loading = false) {
    try {
        if (loading) {
            store.commit('core/updateLoading', { value: true }, { root: true });
        }
        const org = JSON.parse(sessionStorage.getItem('X-ORG'));
        if (org?.id) {
            if (store.state.core.loading && false === loading) {
                setTimeout(refreshToken, delayWhenLoading);
            } else if (gH.isValidToken()) {
                gH.boot(sessionStorage.getItem('token'));
                gH.refreshToken(org?.id)
                    .then((response) => {
                        let token = response.data.token;
                        sessionStorage.setItem('token', token);
                        if (Object.prototype.hasOwnProperty.call(response.data.user, 'permissions_label')) {
                            sessionStorage.setItem("X-PERMISSIONS", JSON.stringify(response.data.user.permissions_label));
                            delete response.data.user.permissions_label;
                        }

                        if (Object.prototype.hasOwnProperty.call(response.data.user, 'roles_label')) {
                            sessionStorage.setItem("X-ROLES", JSON.stringify(response.data.user.roles_label));
                            delete response.data.user.roles_label;
                        }

                        gH.saveUserData('user', response.data.user);
                        gH.saveUserData('org', response.data.current_organization);
                        gH.saveUserData('orgs', response.data.orgs);
                        gH.boot(token);
                        gH.getMenu().then((response) => {
                            store.commit('core/updateMenu', { data: response.data }, { root: true });
                        }).finally(function () {
                            store.commit('core/updateLoading', { value: false }, { root: true });
                            store.commit('core/updateApplicationState', { root: true });
                        });
                    })
                    .catch(handleTokenRefreshError);
            } else {
                handleTokenRefreshError();
            }
        } else if (loading==true) {
            handleTokenRefreshError();
        }
    } catch (e) {
        handleTokenRefreshError();
    }
}

export default {
    refreshToken: refreshToken,
    interval: refreshInterval,
    errorDelay: delayWhenLoading,
};
