import { createI18n } from 'vue-i18n';
import i18nFiles from '@/i18n/index';
import {
    setCulture,
    L10n
} from '@syncfusion/ej2-base';
import en from '@/assets/i18n/src/en-US.json';
import np from '@/assets/i18n/src/ne-NP.json';
import jp from '@/assets/i18n/src/ja.json';

const initialLocale = sessionStorage.getItem('locale') || 'en';
const datetimeFormats = {
    'en-US': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'ja-JP': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
        }
    },
    'ne-NP': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
        }
    }
}

const functionGetStandardCode = (code) => {
    switch (code) {
        case 'en':
            return 'en-US';
        case 'np':
            return 'ne-NP';
        case 'jp':
            return 'ja-JP';
        default:
            return 'en-US';
    }
}

const i18n = createI18n({
    locale: initialLocale,
    messages: i18nFiles.locales,
    // @todo - remove this when all translations are complete
    datetimeFormats,
    missingWarn: false,
    fallbackWarn: false
});
L10n.load({
    'en-US': en[Object.keys(en)],
    'ja-JP': jp[Object.keys(jp)],
    'ne-NP': np[Object.keys(np)],
});
setCulture(functionGetStandardCode(initialLocale));


const changeLocale = async (locale) => {
    if (i18n.global.locale === locale) {
        return; // If the requested locale is the same as the current locale, no need to change
    }

    i18n.global.locale = locale;
    sessionStorage.setItem('locale', locale);
    setCulture(functionGetStandardCode(locale));
};

export { i18n, changeLocale, functionGetStandardCode };
