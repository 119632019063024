<template>
    <div v-if="this.loading" class="ova-area">
        <div class="ovalay"></div>
    </div>

    <div>
        <AppSidebar :key="globalAppState" />
        <div class="wrapper d-flex flex-column min-vh-100 bg-light">
            <AppHeader />
                <div class="body flex-grow-1 px-3">
                    <CContainer :key="globalAppState" fluid>
                    <router-view class="fade-in" />
                    </CContainer>
                </div>
            <AppFooter />
        </div>
    </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import AppSidebar from '@/components/AppSidebar.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppHeader,
    AppSidebar,
    CContainer,
  },
  setup() {
        const store = useStore()
        return {
            loading: computed(() => store.state.core.loading),
            globalAppState: computed(() => store.state.core.globalAppState),
        }
    },
}
</script>
