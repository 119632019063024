export function can(permission) {
    const userPermissions = JSON.parse(sessionStorage.getItem('X-PERMISSIONS'));
    const userRoles = JSON.parse(sessionStorage.getItem('X-ROLES'));

    if (
        userRoles &&
        (
            userRoles.includes('Developer') ||
            userRoles.includes('Global Administrator') ||
            userRoles.includes('Domain Administrator')
        )) {
        return true;
    }

    if (userPermissions && userPermissions.includes(permission)) {
        return true;
    }
    return false;
}

export function is(role) {
    const userRoles = JSON.parse(sessionStorage.getItem('X-ROLES'));
    if (userRoles && userRoles.includes(role)) {
        return true;
    }
    return false;
}
