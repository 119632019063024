export default {
    emoji: '🇺🇸',
    created_at: 'Created At',
    updated_at: 'Updated At',
    crm: {
        title: 'CRM',
        dashboard: 'Dashboard',
        customers: 'Customers',
    },
    navigation: {
        home: 'Home',
        users: 'Users',
        domains: 'Domains',
        test: 'Test',
        notFound: 'Page Not Found',
        forbidden: 'Forbidden',
        serverError: 'Server Error',
        login: 'Login',
        settings: 'Settings',
        register: 'Register',
        organizations: 'Organizations',
        accountGroup: 'Account Groups',
        companies: 'Companies',
        individuals: 'Individuals',
        client: 'Client',
        booking_management: 'Booking Management',
        booking: 'Booking',
        booking_forwards: 'Booking Forwards',
        booking_delivery_pickup: 'Booking Delivery/Pickup',
        management: 'Management',
        awb_inventory: 'AWB Inventory',
        hscode: 'HS Code',
    },
    forms: {
        create: 'New Record',
        view: 'Viewing Record',
        update: 'Editing Record',
        FormAccountGroups: 'Account Group',
        FormBookingForwards: 'Booking Forward',
        FormBookings: 'Booking',
        FormCompanies: 'Organization',
        FormCustomerCompanies: 'Company',
        FormDeliveryPickupOrders: 'Delivery/Pickup Order',
        FormDomainOrganizations: 'Organization',
        FormIndividuals: 'Individual',
        FormUsers: 'User',
        FormUserSettings: 'User',
        FormDomains: 'Domain',
    },
    form_action: {
        save: 'Save',
        submit: 'Submit',
        clear: 'Clear',
        cancel: 'Cancel',
        close: 'Close',
        delete: 'Delete',
        edit: 'Edit',
        update: 'Update',
        add: 'Add',
        FormRecordCreatedSuccess: 'Record created successfully.',
        FormRecordCreatedFailure: 'Record creation failed.',
        FormRecordUpdatedSuccess: 'Record updated successfully.',
        FormRecordUpdatedFailure: 'Record update failed.',
        FormRecordFetchFailure: 'Record fetch failed.',
    },
    v: {
        required: "The field is required",
        default: "The field is not valid",
        alpha: "The field may only contain alphabetic characters",
        alpha_num: "The field may only contain alpha-numeric characters",
        alpha_dash: "The field may contain alpha-numeric characters as well as dashes and underscores",
        alpha_spaces: "The field may only contain alphabetic characters as well as spaces",
        between: "The field must be between {a} and {b}",
        confirmed: "The field confirmation does not match",
        digits: "The field must be numeric and exactly contain {a} digits",
        dimensions: "The field must be {a} pixels by {b} pixels",
        email: "The field must be a valid email",
        not_one_of: "The field is not a valid value",
        ext: "The field is not a valid file",
        image: "The field must be an image",
        integer: "The field must be an integer",
        length: "The field must be {a} long",
        max_value: "The field must be {a} or less",
        max: "The field may not be greater than {a} characters",
        mimes: "The field must have a valid file type",
        min_value: "The field must be {a} or more",
        min: "The field must be at least {a} characters",
        numeric: "The field may only contain numeric characters",
        one_of: "The field is not a valid value",
        regex: "The field format is invalid",
        required_if: "The field is required",
        size: "The field size must be less than {a}KB",
        url: "The field is not a valid URL",
        exists: "The given value must already exist in database.",
    },
    validation: {
        phone: "Phone number may be at most 10 digits, but at least 6 digits.",
        extension: "Extension must be at most 6 digits.",
        intlCode: "Invalid international code format.",
        required: "This field is required.",
        success: 'Request successfully completed.',
        error: 'There are some errors in your submission. Please correct them and try again.',
    },
    grid: {
        select_record: 'Please select a record to view the details.',
        create: 'New Record',
        update: 'Edit Record',
        delete: 'Delete Record',
        read: 'View Record',
        refresh: 'Refresh',
        refresh_table: 'Refresh Grid',
        cancel: 'Cancel',
        error_delete: 'The record could not be deleted.',
        success_delete: 'The record was successfully deleted.',
        success_restore: 'The record was successfully restored.',
        confirm_delete: 'Proceed with deletion?',
        confirm_delete_text: 'The record may be permanently lost. Are you sure you wish to continue?',
        error_restore: 'The record could not be restored.',
        confirm_restore: 'Proceed with restoration?',
        confirm_restore_text: 'This may cause unintended side-effects. Are you sure you wish to continue?',
        restore: 'Restore',
    },
    settings: 'Settings',
    active: 'Active',
    inactive: 'Inactive',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    deletedAt: 'Deleted At',
};
