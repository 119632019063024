<template>
    <CSidebar position="fixed" :unfoldable="sidebarUnfoldable" :visible="sidebarVisible" color-scheme="dark"
        @visible-change="(event) => $store.commit({ type: 'core/updateSidebarVisible', value: event })">
        <AppSidebarNav />
        <CSidebarToggler class="d-none d-lg-flex" @click="$store.commit('core/toggleUnfoldable')" />
    </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
export default {
    name: 'AppSidebar',
    components: {
        AppSidebarNav,
    },
    setup() {
        const store = useStore();
        return {
            sidebarUnfoldable: computed(() => store.state.core.sidebarUnfoldable),
            sidebarVisible: computed(() => store.state.core.sidebarVisible),
        }
    },
}
</script>
