import {ajax} from '@/store/helpers';

class authHelper {
    getUser() {
        try {
            if ( sessionStorage.getItem('token') ) {
                let user = JSON.parse(sessionStorage.getItem('X-USER'));
                let orgs = JSON.parse(sessionStorage.getItem('X-ORGS'));
                let org = JSON.parse(sessionStorage.getItem('X-ORG'));
                let tz = sessionStorage.getItem('X-TZ');
                let token = sessionStorage.getItem('token');

                return {
                    user: user,
                    orgs: orgs,
                    org: org,
                    tz: tz,
                    token: token
                };
            } else {
                throw '[bail]';
            }
        } catch (e) {
            return null;
        }
    }
    getToken() {
        return sessionStorage.getItem("token")
    }
    setUser(param = null) {
        try {
            let data = param;
            if ( param ) {
                data.token = data.token.replace('Bearer ', '');
                sessionStorage.setItem("token", data.token);
                if (Object.prototype.hasOwnProperty.call(data.user, 'permissions_label')) {
                    sessionStorage.setItem("X-PERMISSIONS", JSON.stringify(data.user.permissions_label));
                    delete data.user.permissions_label;
                }
                if (Object.prototype.hasOwnProperty.call(data.user, 'roles_label')) {
                    sessionStorage.setItem("X-ROLES", JSON.stringify(data.user.roles_label));
                    delete data.user.roles_label;
                }
                sessionStorage.setItem("X-USER", JSON.stringify(data.user));
                sessionStorage.setItem("X-ORGS", JSON.stringify(data.orgs));
                sessionStorage.setItem("X-ORG", null);
                ajax.setAuthHeader(data.token);
            }
        } catch (e) {}
    }
    login(data) {
        return ajax.post('auth/login', data);
    }
    logout() {
        ajax.post('auth/logout').catch(() => {
        }).finally(() => {
            ajax.resetAuthHeader();
            sessionStorage.removeItem("X-REDIRECTION");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("X-USER");
            sessionStorage.removeItem("X-PERMISSIONS");
            sessionStorage.removeItem("X-ORGS");
            sessionStorage.removeItem("X-ORG");
            sessionStorage.removeItem("X-TZ");
            sessionStorage.removeItem("X-MENU");
        });
    }
}

export default new authHelper();
